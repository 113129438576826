html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  background: rgb(2, 0, 36);
  background: linear-gradient(
    125deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 73, 128, 1) 100%,
    rgba(252, 252, 252, 1) 100%
  );
  z-index: 1;
  color: #fff;
}

body {
  min-height: 100vh;
  width: 100vw;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
  border-radius: 8px;
}
